import React from "react"
import theme from "theme"
import { Theme, Text, Section, Box, Link } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Nasze usługi | Poznaj nasze usługi w Wildy ATV Rentals</title>
        <meta
          name={"description"}
          content={"Twoja brama do niezapomnianych podróży"}
        />
        <meta
          property={"og:title"}
          content={"Nasze usługi | Poznaj nasze usługi w Wildy ATV Rentals"}
        />
        <meta
          property={"og:description"}
          content={"Twoja brama do niezapomnianych podróży"}
        />
        <meta
          property={"og:image"}
          content={"https://zixelux.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        text-align="center"
        color="--light"
        padding="80px 0"
        sm-padding="40px 0"
      >
        <Text
          as="h1"
          font="--headline1"
          color="--primary"
          md-font="--headline2"
          margin="10px 0 0 0"
        >
          Indywidualne usługi ATV Adventure
        </Text>
        <Text
          as="p"
          font="--lead"
          color="--dark"
          margin="40px 0 20px 0"
          text-align="left"
        >
          W Wildy ATV Rentals staramy się oferować wszechstronne doświadczenie
          przygodowe, które wykracza poza zwykły wynajem quadów. Nasz pakiet
          usług został zaprojektowany tak, aby zaspokoić każdy aspekt przygody
          na świeżym powietrzu, zapewniając niezapomniane i bezproblemowe
          wrażenia. Oto przegląd tego, co oferujemy, aby rozpalić ducha
          przygody:
        </Text>
      </Section>
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        sm-min-height="auto"
        display="flex"
        quarkly-title="Advantages/Features-7"
      >
        <Override
          slot="SectionContent"
          flex-direction="column"
          sm-min-width="280px"
          lg-flex-wrap="wrap"
          display="flex"
        />
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          sm-margin="0px 0px 30px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
          sm-width="100%"
          md-margin="0px 0px 30px 0px"
          margin="0px 0px 64px 0px"
        >
          <Text
            as="h1"
            margin="0px 0px 24px 0px"
            font="--headline2"
            color="--primary"
            width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
            text-align="center"
            md-text-align="center"
            sm-text-align="left"
          >
            Nasze usługi
          </Text>
        </Box>
        <Box
          width="100%"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="48px"
          lg-grid-gap="48px"
          lg-width="100%"
          sm-grid-template-columns="1fr"
          lg-grid-template-columns="repeat(2, 1fr)"
        >
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
              Wycieczki z przewodnikiem
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Spersonalizowane trasy: Dostosuj swoją podróż do poziomu
              doświadczenia i zainteresowań.
              <br />
              <br />
              Doświadczeni przewodnicy: Prowadzony przez ekspertów z pasją do
              outdooru i bezpieczeństwa.
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text
              font="--headline3"
              color="--dark"
              margin="0px 0px 6px 0px"
              sm-text-align="left"
            >
              Wypożyczalnia quadów
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Zróżnicowany wybór: Szeroki wybór pojazdów ATV, od przyjaznych dla
              początkujących po zaawansowane modele.
              <br />
              <br />
              Gotowy do jazdy: Każdy pojazd przechodzi dokładną kontrolę
              konserwacyjną przed każdym wypożyczeniem.
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text
              font="--headline3"
              color="--dark"
              margin="0px 0px 6px 0px"
              sm-text-align="left"
            >
              Wyposażenie bezpieczeństwa
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Kompletny sprzęt: Wysokiej jakości kaski, rękawice i gogle
              zapewniają ochronę.
              <br />
              <br />
              Odprawa przed jazdą: Wszechstronne samouczki dotyczące
              bezpieczeństwa przygotowujące do jazdy.
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text
              font="--headline3"
              color="--dark"
              margin="0px 0px 6px 0px"
              sm-text-align="left"
            >
              Porady dotyczące szlaków
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Niestandardowe trasy: Pomoc w planowaniu jazdy dzięki naszej
              wiedzy na temat najlepszych szlaków.
              <br />
              <br />
              Gotowość na wypadek niepogody: Aktualizacje i wskazówki
              zapewniające bezpieczeństwo i komfort w każdych warunkach
              pogodowych.
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text
              font="--headline3"
              color="--dark"
              margin="0px 0px 6px 0px"
              sm-text-align="left"
            >
              Usługi wsparcia
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Pomoc na miejscu: Natychmiastowa pomoc w razie jakichkolwiek
              problemów podczas przygody.
              <br />
              <br />
              Elastyczne rezerwacje: Łatwe wprowadzanie zmian do rezerwacji w
              ostatniej chwili.
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text
              font="--headline3"
              color="--dark"
              margin="0px 0px 6px 0px"
              sm-text-align="left"
            >
              Oferty specjalne
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Zniżki grupowe i rodzinne: Ciesz się wspólnymi przygodami w
              atrakcyjnej cenie.
              <br />
              <br />
              Pakiety imprezowe: Spersonalizowane oferty na specjalne okazje,
              zapewniające niezapomnianą uroczystość na łonie natury.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          padding="0px 160px 0px 0px"
          border-radius="24px"
          margin="0px 0px 0px 0px"
          width="60%"
          lg-padding="0px 50px 0px 0px"
          lg-width="50%"
          md-width="100%"
          md-padding="0px 0px 0px 0px"
          display="flex"
          flex-direction="column"
          align-items="flex-start"
          md-margin="0px 0px 60px 0px"
          md-align-items="center"
        >
          <Text
            margin="0px 0px 16px 0px"
            font="--headline2"
            color="--dark"
            md-text-align="center"
            sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
          >
            Gotowy na przygodę z nami?
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            font="--base"
            color="--greyD3"
            md-text-align="center"
            md-margin="0px 0px 20px 0px"
          >
            Skontaktuj się z nami już dziś, aby dowiedzieć się, co wypożyczalnia
            quadów Wildy może zaoferować na kolejną przygodę na świeżym
            powietrzu. Nasz zespół chętnie odpowie na Twoje pytania, pomoże Ci
            wybrać idealny pakiet usług i zapewni, że jesteś w pełni wyposażony
            na niezapomnianą podróż. Niezależnie od tego, czy jesteś
            doświadczonym odkrywcą, czy nowicjuszem na szlakach, jesteśmy tutaj,
            aby spełnić Twoje marzenia o przygodzie.
          </Text>
          <Link
            href="/contacts"
            text-decoration-line="initial"
            color="--light"
            font="--lead"
            hover-opacity="0.9"
            background="--color-primary"
            border-radius="8px"
            padding="11px 24px 12px 24px"
          >
            Skontaktuj się z nami
          </Link>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
